import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';

import WrImage from '@commons/wr-image';
import WrButton from '@commons/wr-button';
import WrPointer from '@commons/wr-pointer';
import ModuleTitle from '@commons/module-title';

const content_spacing = '20px';
const md_content_spacing = '30px';
const lg_content_spacing = '30px';

export const StyledContentContainer = styled(StyledContainer)`
    display: grid;
    grid-template-columns: 1fr 150px auto;
    grid-template-rows: auto auto 1fr;

    ${theme.mediaQuerys.xsUp} {
        grid-template-columns: 1fr 166px auto;
    }

    ${theme.mediaQuerys.smUp} {
        grid-template-columns: 1fr 280px auto;
        grid-template-rows: auto auto auto 1fr;
    }

    ${theme.mediaQuerys.mdUp} {
        /* grid-template-columns: 1fr 400px auto; */
        grid-template-columns: 1fr 350px auto;
    }

    ${theme.mediaQuerys.lgUp} {
        /* grid-template-columns: 1fr 500px auto; */
        /* padding-left: 80px; */
        /* padding-left: 80px; */
        grid-template-columns: 1fr 375px auto;
    }
`

export const StyledPointerAndTopIconContainer = styled.div`
    position: relative;
    z-index: 0;
    max-width: 200px;
    width: 50%;
    margin: 0 auto ${content_spacing} auto;
    grid-column: 1 / 3;
    max-width: 240px;

    ${theme.mediaQuerys.smUp} {
        grid-column: 1;
        margin-right: 0;
    }

    ${theme.mediaQuerys.mdUp} {
        max-width: 260px;
        margin-bottom: ${md_content_spacing};
    }

    ${theme.mediaQuerys.lgUp} {
        /* max-width: 280px; */
        max-width: 270px;
        margin-bottom: 20px;
    }
`

export const StyledTopIcon = styled(WrImage)`
    width: 100%;
   
`

export const StyledTitle = styled(ModuleTitle)`
    text-align: center;
    margin-top: 0;
    font-weight: 800;
    margin-bottom: ${content_spacing};
    grid-column: 1 / 3;
    font-style: italic;

    ${theme.mediaQuerys.smUp} {
        grid-column: 1;
        text-align: right;
        margin-left: auto;
        max-width: 575px;
    }

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
    }

    ${theme.mediaQuerys.lgUp} {
        padding: 0 20px;
        margin-bottom: ${lg_content_spacing};
    }
`

export const StyledTextTop = styled.div`
    /* font-size: 1.8rem;
    line-height: 23px; */
    font-size: 1.6rem;
    line-height: 21px;
    text-align: right;
    align-self: center;
    padding-left: 10px;
    grid-column: 1;

    ${theme.mediaQuerys.smUp} {
        font-size: 1.8rem;
        line-height: 23px;
        /* font-size: 2rem;
        line-height: 28px; */
        margin-bottom: ${content_spacing};
        margin-left: auto;
        max-width: 550px;
    }

    ${theme.mediaQuerys.mdUp} {
        /* font-size: 2.2rem;
        line-height: 32px; */
        font-size: 2rem;
    line-height: 28px;
        margin-bottom: ${md_content_spacing};
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: ${lg_content_spacing};
    }
  
    p {
        margin-top: 0;
        margin-bottom: ${content_spacing};

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: ${md_content_spacing};
        }

        ${theme.mediaQuerys.lgUp} {
            margin-bottom: ${lg_content_spacing};
        }
    }

    > :last-child { // last item shouldn't have margin

        ${theme.mediaQuerys.smUp} { 
            margin-bottom: 0 !important;
        }
        
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    position: relative;
    width: calc(100% + 30px);
    align-self: flex-end;
    margin-left: auto;
    grid-column: 2;
    z-index: 1;

    ${theme.mediaQuerys.smUp} {
        grid-row: 1 / 5;
        left: -15px;
        margin-bottom: -40px;
        width: calc(100% + 50px);
        margin-right: -20px;
    }

    ${theme.mediaQuerys.lgUp} {
        margin-bottom: -50px;
    }
`

export const StyledButtonAndPointerContainer = styled.div`
    position: relative;
    margin: 0 auto;
    grid-column: 1 / 3;
    width: 100%;

    ${theme.mediaQuerys.smUp} {
        width: 90%;
        max-width: 500px;
        grid-column: 1;
        margin-right: 0;
    }

    ${theme.mediaQuerys.lgUp} {
        
    }
`

export const StyledButton = styled(WrButton)`
    width: calc(100% + 15px);
    right: -25px;
`

export const StyledButtonPointer = styled(WrPointer)`
    position: absolute;
    top: -70px;
    left: -20px;
    height: 65px;
    transform: rotate(20deg) scaleX(-1);


    ${theme.mediaQuerys.smUp} {
        top: -90px;
        left: -75px;
        height: 110px;
        transform: rotate(05deg) scaleX(-1);
    }

    ${theme.mediaQuerys.mdUp} {       
        top: -80px;
    }

    ${theme.mediaQuerys.lgUp} {
        top: -90px;
        left: -90px;
        height: 120px;
        transform: rotate(0deg) scaleX(-1);
    }
`