import React from 'react'
import { StyledContentContainer, StyledButton, StyledPointerAndTopIconContainer, StyledButtonPointer, StyledFeaturedImage, StyledTopIcon, StyledTitle, StyledTextTop, StyledButtonAndPointerContainer } from './styles';

import { StyledModuleContainer } from '@styles/global-components/_containers';

export default function SingleCtaWithLargeImage({ module }) {
    return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            innerSpacing={module.enableIconPointer ? true : module.enablePointer ? true : ''}
            isOverflowHidden={module.enableIconPointer ? true : module.enablePointer ? true : ''}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="single-sign-up-cta"
        >
            <StyledContentContainer>  
                <StyledPointerAndTopIconContainer>
                    {module.topIcon && 
                        <StyledTopIcon 
                            imageFile={module.topIcon.imageFile}
                            alt={module.topIcon.altText}
                        />
                    }
                </StyledPointerAndTopIconContainer>
              
                    {module.title && 
                        <StyledTitle
                            titleFontOptions={{ mobile: 'md', desktop: 'lg' }}
                        > 
                            {module.title}
                        </StyledTitle>
                    }

                    {module.textTop && 
                        <StyledTextTop 
                            dangerouslySetInnerHTML={{ __html: module.textTop }}
                        />
                    }
    
                    {module.featuredImage && 
                        <StyledFeaturedImage 
                            imageFile={module.featuredImage.imageFile}
                            alt={module.featuredImage.altText}
                        />
                    }

                <StyledButtonAndPointerContainer>
                    {module.enablePointer && 
                        <StyledButtonPointer 
                            color={module.pointerColor} 
                        />
                    }
                    
                    {module.buttonLink && 
                        <StyledButton 
                            href={module.buttonLink.url} 
                            buttonOptions={module.buttonOptions}
                            fontSize="lg"
                            radius="md"

                        >
                            {module.buttonLink.title}
                        </StyledButton>
                    }
                </StyledButtonAndPointerContainer>

            </StyledContentContainer>
        </StyledModuleContainer>
    )
}
